<template>
  <div class="feature-box">
    <div v-for="item in featureList" :key="item.key" class="feature-item">
      <div class="item-title">{{ item.title }}</div>
      <div class="item-desc" v-html="item.desc"></div>
      <img class="item-img" :src="item.imgSrc" >
    </div>
  </div>
</template>

<script>
import cms from '@/utils/cms';

export default {
  name: 'Feature',

  data() {
    return {
      featureList: [],
    };
  },

  mounted() {
    this.queryFeatures();
  },

  methods: {
    queryFeatures() {
      const loading = this.$loading();
      const target = cms.graphQL.queryFeatures;
      this.$axios({
        url: target.url,
        method: 'post',
        data: {
          query: target.query,
        },
        json: true,
      })
        .then((result) => {
          loading.close();
          if (result.data && Array.isArray(result.data[target.column])) {
            this.formatData(result.data[target.column]);
          } else {
            this.$message.error('获取数据失败');
          }
        })
        .catch((e) => {
          loading.close();
          this.$message.error('获取数据失败');
          throw e;
        });
    },
    formatData(list) {
      const arr = [];
      list.forEach((item) => {
        if (!item.image?.publicUrl) return;
        item.key = item.id;
        item.title = item.name || '';
        item.imgSrc = this.$utils.getImagePath(item.image?.publicUrl);
        item.desc = this.$utils.formatStr4textArea(item.desc || '');
        arr.push(item);
      });
      this.featureList = list;
    },
  },
};
</script>

<style lang="scss" scoped>
.feature-box {
  padding: 0 20px;
}
.feature-item {
  margin: auto;
  max-width: 1200px;
  padding: 50px 0;
  border-top: 1px solid $border-light;
  &:first-child {
    border-top: 0;
  }
}
.item-title {
  font-size: 36px;
  font-weight: bold;
}
.item-desc {
  margin-top: 30px;
  font-size: 20px;
  color: $text-secondary;
}
.item-img {
  border-radius: 4px;
  margin-top: 20px;
  width: 100%;
  height: auto;
}
</style>
