<template>
  <div class="contact-box">
    <div ref="map" class="map-box"></div>
    <div class="info-box">
      <div class="info-content">
        <div class="qr-box">
          <img class="qr" src="/settings/qr.jpg">
          <div class="qr-info">扫码关注灯翼公众号</div>
        </div>
        <div class="company-info">
          <div class="name">灯翼智能科技（苏州）有限公司</div>
          <div class="contact-info">
            <div class="line">
              <div class="label">地址：</div>
              <div class="content">苏州工业园区集贤街55号矩子大厦806室</div>
            </div>
            <div v-if="contactTel" class="line">
              <div class="label">邮箱：</div>
              <div class="content">{{contactTel}}</div>
            </div>
            <div v-if="contactEMail" class="line">
              <div class="label">电话：</div>
              <div class="content">{{contactEMail}}</div>
            </div>
            <div v-if="contactPhone" class="line">
              <div class="label">手机：</div>
              <div class="content">{{contactPhone}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',

  data() {
    return {
      loadInterval: 0,
      isMapLoaded: false,
      loadMapMinWidth: 900,
      contactTel: window.settings?.contactTel || '',
      contactEMail: window.settings?.contactEMail || '',
      contactPhone: window.settings?.contactPhone || '',

    };
  },

  mounted() {
    this.initMap();
    this.loadInterval = setInterval(this.initMap, 300);
    window.addEventListener('resize', this.initMap);
  },
  beforeDestroy() {
    this.removeEvent();
  },
  methods: {
    initMap() {
      if (!window.TMap || window.innerWidth < 900 || this.isMapLoaded) return;
      this.isMapLoaded = true;
      this.removeEvent();
      const center = new window.TMap.LatLng(31.25054, 120.731823);
      const mapObj = new window.TMap.Map(this.$refs.map, {
        center,
        zoom: 17,
      });
      mapObj.removeControl(window.TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
      mapObj.removeControl(window.TMap.constants.DEFAULT_CONTROL_ID.ROTATION);
      const markerLayer = new window.TMap.MultiMarker({
        map: mapObj, // 指定地图容器
        // 样式定义
        styles: {
          myStyle: new window.TMap.MarkerStyle({
            width: 27,
            height: 43,
            src: 'img/marker.png',
            anchor: { x: 13.5, y: 28 },
          }),
        },
        // 点标记数据数组
        geometries: [{
          id: '1',
          styleId: 'myStyle',
          position: center,
          properties: {
            title: '灯翼智能科技（苏州）有限公司',
          },
        },
        ],
      });
      const infoWindow = new window.TMap.InfoWindow({
        map: mapObj,
        zIndex: 10,
        position: center,
        offset: { x: 0, y: -32 },
        content: '灯翼智能科技（苏州）有限公司',
      });
      infoWindow.close();
      markerLayer.on('click', (evt) => {
        infoWindow.setPosition(evt.geometry.position);
        infoWindow.open();
      });
    },
    removeEvent() {
      clearInterval(this.loadInterval);
      window.removeEventListener('resize', this.initMap);
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-box {
  position: relative;
  display: flex;
}
.map-box {
  position: relative;
  height: 55vw;
  width: 65vw;
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-bottom: 55vw solid $white;
    border-left: 10vw solid transparent;
  }
}
.info-box {
  padding: 10vw 10px 0;
  max-width: 360px;
  .qr-box {
    text-align: center;
    .qr {
      width: 230px;
      height: 230px;
      border: 1px solid $border-light;
      border-radius: 4px;
    }
    .qr-info {
      font-size: 14px;
      margin-top: 10px;
      color: $text-secondary;
    }
  }
  .company-info {
    margin-top: 40px;
    .name {
      font-size: 24px;
      font-weight: bold;
    }
    .contact-info {
      .line {
        margin-top: 15px;
        position: relative;
        font-size: 16px;
        color: $text-base;
        padding-left: 48px;
        .label {
          position: absolute;
          left: 0;
        }
      }
    }
  }
}
</style>
