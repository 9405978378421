<template>
  <div class="page-header">
    <div class="content-box">
      <el-dropdown trigger="click" @command="handleMenuCommand">
        <i class="iconfont icon-menu menu-icon" />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item  v-for="item in tabList" :key="item.code" :command="item.code">{{ item.name }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="logo-box">
        <img class="logo" src="/settings/logo.png" />
        <div v-if="title" class="title">{{ title }}</div>
      </div>
      <div class="tab-box">
        <div v-for="item in tabList" :key="item.code" class="tab-item" :class="{active: activeTab === item.code}" @click="toggleTab(item)">
          {{ item.name }}
        </div>
      </div>
      <div v-if="enableLogin" class="login-box">
        <div v-if="!isLogin" class="to-login" @click="toLogin">登录</div>
        <div v-else class="user-box"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  data() {
    return {
      enableLogin: !!window.settings.enableLogin && !window.isMobile,
      title: window.settings.title || '',
      activeTab: this.$route.name,
      tabList: [
        {
          name: '首页',
          code: 'index',
          path: '/',
        },
        {
          name: '产品特色',
          code: 'feature',
          path: '/feature',
        },
        {
          name: '宣传视频',
          code: 'video',
          path: '/video',
        },
        {
          name: '案例介绍',
          code: 'demo',
          path: '/demo',
        },
        {
          name: '联系合作',
          code: 'contact',
          path: '/contact',
        },
      ],
      tabMap: {},
      isLogin: false,
    };
  },

  mounted() {
    this.tabList.forEach((item) => {
      this.tabMap[item.code] = item;
    });
    this.getUserInfo();
  },

  methods: {
    handleMenuCommand(code) {
      const targetTab = this.tabMap[code];
      this.toggleTab(targetTab);
    },
    toggleTab(tab) {
      if (this.activeTab === tab.code) return;
      this.activeTab = tab.code;
      this.$router.replace({
        path: tab.path,
      });
    },
    toLogin() {},
    getUserInfo() {},
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  padding: 0 20px;
  box-shadow: 0px 4px 20px 0px rgba(199,199,199,0.2);
  position: relative;
  z-index: 2;
}
.content-box {
  max-width: 1200px;
  margin: auto;
  height: 70px;
  display: flex;
  align-items: center;
}
.menu-icon {
  display: none;
  margin-right: 20px;
  font-size: 24px;
}
.logo-box {
  display: flex;
  align-items: center;
  .logo {
    max-height: 70px;
  }
  .title {
    margin-left: 10px;
    font-size: $font-size-6;
    font-weight: bold;
  }
}

.tab-box {
  margin-left: 80px;
  display: flex;
  align-items: center;
  .tab-item {
    cursor: pointer;
    font-size: $font-size-2;
    &.active,
    &:hover {
      color: $primary;
    }
    & + .tab-item {
      margin-left: 60px;
    }
  }
}

.login-box {
  margin-left: auto;
  .to-login {
    cursor: pointer;
    &:hover {
      color: $primary;
    }
  }
}
</style>
