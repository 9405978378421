import Vue from 'vue';
import qs from 'qs';

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import '@/plugins/axios';
import '@/plugins/element-ui';
import date from '@/utils/date';
import utils from '@/utils/index';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.prototype.$utils = utils;
Vue.prototype.$qs = qs;
Vue.prototype.$date = date;
const instance = new Vue({
  router,
  store,
  render: (h) => h(App),
});
instance.$mount('#app');
window.instanceObj = instance;
document.title = instance.$route.meta.title;

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  NProgress.start();
  next();
});
router.afterEach(() => {
  NProgress.done();
});
