import Vue from 'vue';
import Router from 'vue-router';
import Index from './views/Index';
import Feature from './views/Feature';
import Video from './views/Video';
import Demo from './views/Demo';
import Contact from './views/Contact';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index,
      meta: {
        title: '灯翼智能-首页',
      },
    },
    {
      path: '/feature',
      name: 'feature',
      component: Feature,
      meta: {
        title: '灯翼智能-产品特色',
      },
    },
    {
      path: '/video',
      name: 'video',
      component: Video,
      meta: {
        title: '灯翼智能-宣传视频',
      },
    },
    {
      path: '/demo',
      name: 'demo',
      component: Demo,
      meta: {
        title: '灯翼智能-案例演示',
      },
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
      meta: {
        title: '灯翼智能-联系合作',
      },
    },
  ],
});
