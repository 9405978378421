<template>
  <div class="page-footer">
    <div class="container">
      <div class="left">
        <div class="nav-box">
          <div v-for="item in tabList" :key="item.code" class="nav-item" @click="toggleTab(item)">{{ item.name }}</div>
        </div>
        <div class="sub-info">
          <div class="line">Copyright©2022-{{new Date().getFullYear()}} 灯翼智能科技（苏州）有限公司 版权所有</div>
          <div class="line"><a target="_blank" href="https://beian.miit.gov.cn/">苏ICP备2022038080号</a></div>
        </div>
      </div>
      <div class="right">
        <div v-if="contactTel" class="contact">
          <img class="icon" src="img/icon-phone.png">
          <div class="tel-box">
            <div class="area-code">{{ areaCode }}</div>
            <div class="tel">{{ tel }}</div>
            <div class="info">咨询热线</div>
          </div>
        </div>
        <div class="qr-box">
          <img class="footer-qr" src="/settings/qr.jpg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',

  data() {
    return {
      contactTel: window.settings?.contactTel || '',
      areaCode: '',
      tel: '',
      tabList: [
        {
          name: '首页',
          code: 'index',
          path: '/',
        },
        {
          name: '产品特色',
          code: 'feature',
          path: '/feature',
        },
        {
          name: '宣传视频',
          code: 'video',
          path: '/video',
        },
        {
          name: '案例介绍',
          code: 'demo',
          path: '/demo',
        },
        {
          name: '联系合作',
          code: 'contact',
          path: '/contact',
        },
      ],
    };
  },

  mounted() {
    const [areaCode, tel] = this.contactTel.split('-');
    this.areaCode = `${areaCode}-`;
    this.tel = tel;
  },

  methods: {
    toggleTab(tab) {
      if (this.$route.name === tab.code) return;
      this.$router.replace({
        path: tab.path,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-footer {
  height: 200px;
  padding: 0 20px;
  background-color: $background-light;
  display: flex;
  align-items: center;
}
.container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.nav-box {
  display: flex;
  font-size: $font-size-1;
  .nav-item {
    cursor: pointer;
    padding: 0 30px;
    border-left: 1px solid $text-secondary;
    &:first-child {
      border-left: 0;
    }
  }
}
.sub-info {
  padding-left: 30px;
  font-size: $font-size-0;
  margin-top: 20px;
  color: $text-secondary;
  line-height: 22px;
  a {
    &,
    &:visited,
    &:active,
    &:focus {
      color: $text-secondary;
    }
  }
}
.right {
  position: relative;
  display: flex;
  padding-right: 110px;
}
.contact {
  display: flex;
  .icon {
    position: relative;
    top: 5px;
    width: 19px;
    height: 33px;
    margin-right: 10px;
  }
  .tel-box {
    .area-code {
      font-size: 14px;
    }
    .tel {
      font-size: 22px;
    }
    .info {
      text-align: right;
      font-size: 12px;
      color: $text-secondary;
    }
  }
}
.qr-box {
  position: absolute;
  right: 0;
  top: -20px;
  .footer-qr {
    border: 1px solid $border-light;
    width: 100px;
    height: 100px;
  }
}
</style>
