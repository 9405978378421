<template>
  <div class="index-box">
    <div class="banner-box">
      <div v-for="item in bannerList" :key="item.key" class="banner-item">
        <img class="banner-img" :src="item.imgSrc" />
        <div class="banner-text-box">
          <div class="banner-text" v-html="item.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cms from '@/utils/cms';

export default {
  name: 'Index',

  data() {
    return {
      bannerList: [],
    };
  },

  mounted() {
    this.queryIndexBanners();
  },

  methods: {
    queryIndexBanners() {
      const loading = this.$loading();
      const target = cms.graphQL.queryIndexBanners;
      this.$axios({
        url: target.url,
        method: 'post',
        data: {
          query: target.query,
        },
        json: true,
      })
        .then((result) => {
          loading.close();
          if (result.data && Array.isArray(result.data[target.column])) {
            this.formatData(result.data[target.column]);
          } else {
            this.$message.error('获取数据失败');
          }
        })
        .catch((e) => {
          loading.close();
          this.$message.error('获取数据失败');
          throw e;
        });
    },
    formatData(list) {
      const arr = [];
      list.forEach((item) => {
        if (!item.image?.publicUrl) return;
        item.key = item.id;
        item.imgSrc = this.$utils.getImagePath(item.image?.publicUrl);
        item.content = this.$utils.formatStr4textArea(item.text || '');
        arr.push(item);
      });
      this.bannerList = list;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-item {
  position: relative;
}
.banner-img {
  width: 100%;
  height: auto;
  min-height: 300px;
  object-fit: cover;
}
.banner-text-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 5% 15% 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-text {
  color: $white;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  background: rgba($color: #000, $alpha: 0.2);
  border-radius: 30px;
  box-shadow: 0 0 60px 60px rgba($color: #000, $alpha: 0.2);
}
</style>
