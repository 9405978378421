<template>
  <div class="video-box">
    <div v-for="item in videoList" :key="item.key" class="video-item">
      <img v-if="item.imgSrc" class="item-bg-img" :src="item.imgSrc" >
      <div class="item-bg-mask"></div>
      <div class="item-content">
        <div class="item-title">{{ item.title }}</div>
        <div class="item-desc">{{ item.desc }}</div>
        <div class="item-video">
          <video-player :option="{ src: item.videoSrc }"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cms from '@/utils/cms';
import VideoPlayer from '@/common/components/VideoPlayer';

export default {
  name: 'Video',
  components: {
    VideoPlayer,
  },
  data() {
    return {
      videoList: [],
    };
  },

  mounted() {
    this.queryVideos();
  },

  methods: {
    queryVideos() {
      const loading = this.$loading();
      const target = cms.graphQL.queryVideos;
      this.$axios({
        url: target.url,
        method: 'post',
        data: {
          query: target.query,
        },
        json: true,
      })
        .then((result) => {
          loading.close();
          if (result.data && Array.isArray(result.data[target.column])) {
            this.formatData(result.data[target.column]);
          } else {
            this.$message.error('获取数据失败');
          }
        })
        .catch((e) => {
          loading.close();
          this.$message.error('获取数据失败');
          throw e;
        });
    },
    formatData(list) {
      const arr = [];
      list.forEach((item) => {
        if (!item.video?.publicUrl) return;
        item.key = item.id;
        item.title = item.name || '';
        item.imgSrc = this.$utils.getImagePath(item.backgroundImage?.publicUrl);
        item.videoSrc = this.$utils.getVideoPath(item.video?.publicUrl);
        item.desc = item.desc || '';
        arr.push(item);
      });
      this.videoList = list;
    },
  },
};
</script>

<style lang="scss" scoped>
.video-item {
  position: relative;
  padding: 0 20px;
  background: #2e3843;
}
.item-bg-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item-bg-mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #2e3843, $alpha: 0.8);
}
.item-content {
  margin: auto;
  max-width: 1200px;
  padding: 80px 0 50px;
  color: $white;
  position: relative;
  z-index: 2;
}
.item-title {
  text-align: right;
  font-size: 36px;
  font-weight: bold;
}
.item-desc {
  text-align: right;
  margin-top: 30px;
  font-size: 20px;
  padding-left: 20%;
}
.item-video {
  margin-top: 40px;
}
</style>
