<template>
  <div id="app">
    <page-header />
    <transition name="fade" mode="out-in">
      <router-view class="router-content-box"></router-view>
    </transition>
    <page-footer />
  </div>
</template>

<script>
import PageHeader from './components/PageHeader.vue';
import PageFooter from './components/PageFooter.vue';

export default {
  name: 'app',
  components: {
    PageHeader,
    PageFooter,
  },
  data() {
    return {

    };
  },
  methods: {

  },
  mounted() {},
};
</script>
<style lang="scss">
  @include init-base;
  html {
    overflow: overlay;
  }
  body {
    overflow: visible !important;
  }
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    min-height: 100%;
    a {
      text-decoration: none;
    }
  }
  .router-content-box {
    min-height: calc(100vh - 270px);
  }
  .fade-enter {
    opacity: 0;
  }

  .fade-leave {
    opacity: 1;
  }

  .fade-enter-active {
    transition: opacity .5s;
  }

  .fade-leave-active {
    opacity: 0;
    transition: opacity .5s;
  }

  @keyframes active {
    from {
      height: 0;
    }
    to {
      height: 3px;
    }
  }
</style>
<style scoped lang="scss">
/deep/ {
  @media screen and (max-width: 1200px) {
    .index-box {
      .banner-item {
        .banner-text {
          font-size: 40px;
        }
      }
    }
    .video-box {
      .item-content {
        padding: 50px 0 30px;
      }
      .item-title {
        font-size: 28px;
      }
      .item-desc {
        margin-top: 20px;
        font-size: 16px;
      }
    }
    .feature-box,
    .demo-box {
      .demo-item {
        padding: 40px 0;
      }
      .item-title {
        font-size: 28px;
      }
      .item-desc {
        margin-top: 25px;
        font-size: 16px;
      }
    }
    .contact-box {
      .info-box {
        padding-top: 50px;
        .qr-box {
          .qr {
            width: 180px;
            height: 180px;
          }
        }
        .company-info {
          margin-top: 30px;
          .name {
            font-size: 20px;
          }
          .contact-info {
            padding-right: 20px;
            .line {
              margin-top: 10px;
              font-size: 14px;
              padding-left: 40px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    .page-header {
      .menu-icon {
        display: inline-block;
      }
      .tab-box {
        display: none;
      }
    }
    .index-box {
      .banner-item {
        .banner-text {
          font-size: 5vw;
        }
      }
    }
    .video-box {
      .item-content {
        padding: 40px 0 25px;
      }
      .item-title {
        font-size: 24px;
      }
      .item-desc {
        padding-left: 0;
        margin-top: 15px;
        font-size: 14px;
      }
      .item-video {
        margin-top: 25px;
      }
    }
    .feature-box,
    .demo-box {
      .demo-item {
        padding: 30px 0;
      }
      .item-title {
        font-size: 24px;
      }
      .item-desc {
        margin-top: 20px;
        font-size: 14px;
      }
    }
    .contact-box {
      display: block !important;
      padding: 60px 20px;
      .map-box {
        display: none;
      }
      .info-box {
        margin: auto;
        padding-top: 0;
        .qr-box {
          .qr {
            width: 180px;
            height: 180px;
          }
        }
        .company-info {
          margin-top: 30px;
          .name {
            font-size: 20px;
            text-align: center;
          }
          .contact-info {
            margin-top: 20px;
            .line {
              margin-top: 10px;
              font-size: 14px;
              padding-left: 40px;
            }
          }
        }
      }
    }
    .page-footer {
      height: auto;
      padding: 20px;
      .container {
        display: block;
      }
      .nav-box {
        display: none;
      }
      .left {
        width: 100%;
        .sub-info {
          margin-top: 0;
          padding-left: 0;
          text-align: center;
        }
      }
      .right {
        display: none;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .index-box {
      .banner-item {
        .banner-text-box {
          margin: 5% 8% 10%;
        }
        .banner-text {
          font-size: 24px;
        }
      }
    }
  }
}
</style>
